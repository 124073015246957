import React, { useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { useSearchParams } from 'react-router-dom'

import * as S from './Popup.styled'

import { ReactComponent as Print } from '@/assets/svg/print.svg'
import { ReactComponent as Download } from '@/assets/svg/file-download.svg'
import { ReactComponent as LinkExternal } from '@/assets/svg/link-external.svg'
import { ReactComponent as OnAir } from '@/assets/svg/voice_on_air.svg'

import { audioDetailType } from '@/types/audiosType'
import { convertDuration, hasPopupSource, keywordHighlighting } from '@/functions'

import { useSelector } from '@/store'
import { useTranslation } from 'react-i18next'

export function Voice() {
  /** router */
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get('keyword')

  /** ref */
  const contentRef = useRef<HTMLDivElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)

  /** useState */
  const [showAllDesc, setShowAllDesc] = useState(false)

  /** redux */
  const { detailData } = useSelector((state) => state.search)

  const { t } = useTranslation()

  const {
    file,
    title,
    contents,
    data_src_l_cd: dataSrcLCd,
    data_src_m_cd: dataSrcMCd,
    data_src_s_cd: dataSrcSCd,
    source_url: sourceUrl,
    date,
    col_dt: colDt,
    sentences_inner_hits: sentencesInnerHits,
  } = detailData as audioDetailType

  const seeker = sentencesInnerHits ? [...sentencesInnerHits].sort((a, b) => a.start - b.start) : []

  /** 더보기 버튼 */
  const handleShowMoreContent = () => {
    setShowAllDesc(false)
  }

  /** 더보기 버튼 활성화 */
  useEffect(() => {
    if (contentRef.current && contentRef.current?.clientHeight > 264) {
      setShowAllDesc(true)
    }
  }, [])

  return (
    <>
      <style>{S.printStyle}</style>
      <S.PopupLayout className='wrap_popup' $showContent={showAllDesc}>
        <div className='content_media'>
          <div className='media-container' style={{ width: seeker.length > 0 ? 720 : 960 + 'px' }}>
            <OnAir />
            <audio controls ref={audioRef}>
              <source src={`${process.env.REACT_APP_BASE_URL}${file}`} />
            </audio>
          </div>
          {seeker.length > 0 ? (
            <div className='sentences_inner_hits'>
              {seeker.map((sentence, index) => {
                return (
                  keyword !== null && (
                    <p
                      key={index}
                      title={sentence.text}
                      onClick={() => {
                        audioRef.current != null
                          ? (audioRef.current.currentTime = sentence.start)
                          : null
                      }}
                    >
                      <span className='timestamp'>{convertDuration(sentence.start)}</span>
                      <span>{parse(keywordHighlighting(sentence.text ?? '', keyword))}</span>
                    </p>
                  )
                )
              })}
            </div>
          ) : null}
        </div>
        <div className='popup_info'>
          <div className='info_detail'>
            <dl>
              <div>
                <dt>{t('components.Popup.Src')}</dt>
                <dd>
                  <span>{hasPopupSource(dataSrcLCd, dataSrcMCd, dataSrcSCd)}</span>
                  {sourceUrl !== '' && (
                    <a href={sourceUrl} target='_blank' rel='noreferrer noopener'>
                      <LinkExternal />
                    </a>
                  )}
                </dd>
              </div>
              <div>
                <dt>{t('components.Popup.Pub')}</dt>
                <dd>
                  <span>{date.slice(0, -9).replaceAll('-', '. ')}.</span>
                </dd>
              </div>
              <div>
                <dt>{t('components.Popup.Col')}</dt>
                <dd>
                  <span>{colDt.slice(0, -9).replaceAll('-', '. ')}.</span>
                </dd>
              </div>
            </dl>
          </div>
          <div className='info_cotrol'>
            <a href='#none' onClick={() => window.print()}>
              <Print />
              {t('components.Popup.Print')}
            </a>
            <button
              type='button'
              onClick={() => {
                fetch(`${process.env.REACT_APP_BASE_URL}${file}`).then((response) => {
                  response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')

                    a.href = url
                    a.download = file.split('/').slice(-1)[0]
                    a.click()
                  })
                })
              }}
            >
              <Download />
              {t('components.Popup.Download')}
            </button>
          </div>
        </div>
        <div className='popup_content'>
          <div className='content_txt' ref={contentRef}>
            {keyword !== null && (
              <>
                <h3>{parse(keywordHighlighting(title, keyword))}</h3>
                <p>{parse(keywordHighlighting(contents, keyword))}</p>
              </>
            )}
          </div>
          <button type='button' className='bth_viewMore' onClick={handleShowMoreContent}>
            {t('components.Popup.More')}
          </button>
        </div>
      </S.PopupLayout>
    </>
  )
}
