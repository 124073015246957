import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  SearchResultNumberBox,
  ViewMoreButton,
  SectionTitle,
  RelatedWord,
  DocItem,
  ImgItem,
  AudioItem,
  Document,
  Image,
  Movie,
  Voice,
  VideoItem,
  SearchNoResult,
  Loading,
  PopupLoading,
  Chat,
} from '@/components'

import useModal from '@/hooks/useModal'

import styled from '@emotion/styled'
import { CommonLayout } from '@/theme'

import { useDispatch } from 'react-redux'
import { useSelector } from '@/store'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { db, Keyword } from '@/db/RecentlySearchedKeywordDB'
import { useTranslation } from 'react-i18next'
import { parseSearchParams } from '@/functions/parseSearchParams'

const Container = styled(CommonLayout)`
  min-height: calc(100vh - 317px);

  .search_result_all_wrapper {
    display: flex;
    flex-flow: column nowrap;
  }

  /* 검색 결과 영역 */
  .search_result_inner {
    width: 792px;
    max-height: calc(100% - 50px);
    height: 100%;

    padding-top: 30px;
    padding-bottom: 100px;
  }

  .search_result_area:nth-of-type(n + 2) {
    margin-top: 80px;
  }

  .bth_viewMoer {
    margin-top: 10px;
  }

  /** 이미지 */
  .list_img {
    display: flex;

    li {
      width: calc((100% - 72px) / 4);
    }

    li:nth-of-type(n + 2) {
      margin-left: 24px;
    }
  }

  /** 비디오 */
  .movie_list {
    display: flex;
    width: 100%;

    li {
      width: calc((100% - 48px) / 3);
    }

    li:nth-of-type(n + 2) {
      margin-left: 24px;
    }
  }

  /** 음성 */
  .voice_list {
    width: 100%;

    li:nth-of-type(n + 2) {
      margin-top: 10px;
    }
  }

  /** 연관 검색어 */
  .addition_info_side_area {
    position: relative;
    margin-top: 50px;
    margin-left: 24px;
    width: 384px;

    & > div {
      position: sticky;
      top: 50px;
      display: flex;
      flex-direction: column;
      gap: 17px;
    }
  }

  .related_search_box {
    /* position: sticky;
    top: 50px; */
    padding: 30px 35px 32px 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

    > p {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1em;
      color: #222222;
    }
  }
`
/** 팝업 컴포넌트 */
const returnPopupComponent: { [key: string]: JSX.Element } = {
  doc: <Document />,
  img: <Image />,
  mov: <Movie />,
  voi: <Voice />,
}

export function SearchResultAll(): JSX.Element {
  /** router */
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  /** redux */
  const dispatch = useDispatch()
  const { allSearchDataCount, searchData, relatedWord, detailData, detailDataType } = useSelector(
    (state) => state.search,
  )

  /** ref */
  const videoItemRef = useRef<HTMLLIElement | null>(null)

  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  /** useState */
  const [videoItemWidth, setVideoItemWidth] = useState(0)
  const [RecentlyWord, setRecentlyWord] = useState<Keyword[]>()

  const { ModalPortal, openModal } = useModal(targetRef)

  /** 팝업 컴포넌트 */
  const Popup = ({ type }: { type: string }) => returnPopupComponent[type]

  /** 팝업 오픈 */
  const handleShowPopup = useCallback(
    (e: React.MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLElement

      const data = currentTarget.dataset.type
      if (data) {
        openModal()
        setTargetRef(currentTarget)
        dispatch(
          searchSagaActions.setDetailDataAction({
            id: currentTarget.id,
            type: data,
          }),
        )
      }
    },
    [dispatch, openModal],
  )

  const handleKeyDownShowPopup = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      const currentTarget = e.currentTarget as HTMLElement

      const data = currentTarget.dataset.type

      if (data) {
        openModal()
        setTargetRef(currentTarget)
        dispatch(
          searchSagaActions.setDetailDataAction({
            id: currentTarget.id,
            type: data,
          }),
        )
      }
    },
    [dispatch, openModal],
  )

  //
  const generateRouteWithParams = useCallback(
    (path: string) => {
      navigate({
        pathname: path,
        search: `?${searchParams.toString()}`,
      })
    },
    [navigate, searchParams],
  )

  useEffect(() => {
    if (videoItemRef.current) setVideoItemWidth(videoItemRef.current?.clientWidth)
  }, [])

  /** 검색 결과 데이터 요청 */
  useEffect(() => {
    // const keyword = searchParams.get('keyword')
    // const s_event_date = searchParams.get('s_event_date')
    // const e_event_date = searchParams.get('e_event_date')

    // if (keyword === null) return

    // const params: Record<string, string> = {
    //   keyword,
    //   s_event_date: s_event_date ?? '',
    //   e_event_date: e_event_date ?? '',
    // }

    const keyword = searchParams.get('keyword')

    if (keyword === null) return

    const params = { ...parseSearchParams(searchParams), keyword }

    dispatch(searchSagaActions.getSearchAllSagaAction(params))
  }, [dispatch, searchParams])

  useEffect(() => {
    if (relatedWord.length !== 0) return

    db.getAll(setRecentlyWord)
  }, [relatedWord])

  const { t } = useTranslation()

  // 데이터가 없는 경우
  if (!searchData)
    return (
      <Container className='search_result_all_container'>
        <Loading />
      </Container>
    )

  if (allSearchDataCount === 0)
    return (
      <Container className='search_result_all_container'>
        <div className='search_result_all_wrapper'>
          <SearchResultNumberBox count={0} />
          <div className='search_result_inner'>
            <SearchNoResult sectionClassName='all' />
          </div>
        </div>
      </Container>
    )

  return (
    <Container className='search_result_all_container'>
      <div className='search_result_all_wrapper'>
        <SearchResultNumberBox count={allSearchDataCount} />

        <div className='search_result_inner'>
          {searchData.documents.data.length !== 0 && (
            <section className='doc search_result_area'>
              <SectionTitle title='문서' />
              <ul className='list_doc'>
                {searchData.documents.data.slice(0, 5).map((item) => (
                  <DocItem
                    key={item.id}
                    id={item.id}
                    data-type={'doc'}
                    dataItem={item}
                    tabIndex={0}
                    onClick={handleShowPopup}
                    onKeyDown={handleKeyDownShowPopup}
                  />
                ))}
              </ul>
              <ViewMoreButton
                onClick={() => {
                  generateRouteWithParams('/search/document')
                }}
              />
            </section>
          )}

          {searchData.images.data.length !== 0 && (
            <section className='img search_result_area'>
              <SectionTitle title='이미지' />
              <ul className='list_img'>
                {searchData.images.data.slice(0, 4).map((item) => (
                  <ImgItem
                    key={item.id}
                    id={item.id}
                    data-type={'img'}
                    dataItem={item}
                    tabIndex={0}
                    onClick={handleShowPopup}
                    onKeyDown={handleKeyDownShowPopup}
                  />
                ))}
              </ul>
              <ViewMoreButton
                onClick={() => {
                  generateRouteWithParams('/search/image')
                }}
              />
            </section>
          )}

          {searchData.videos.data.length !== 0 && (
            <section className='movie search_result_area'>
              <SectionTitle title='동영상' />
              <ul className='movie_list'>
                {searchData.videos.data.slice(0, 3).map((item, index) => (
                  <VideoItem
                    ref={videoItemRef}
                    key={index}
                    id={item.id}
                    data-type={'mov'}
                    dataItem={item}
                    width={videoItemWidth}
                    tabIndex={0}
                    onClick={handleShowPopup}
                    onKeyDown={handleKeyDownShowPopup}
                  />
                ))}
              </ul>
              <ViewMoreButton
                onClick={() => {
                  generateRouteWithParams('/search/video')
                }}
              />
            </section>
          )}

          {searchData.audios.data.length !== 0 && (
            <section className='voice search_result_area'>
              <SectionTitle title='음성' />
              <ul className='voice_list'>
                {searchData.audios.data.slice(0, 3).map((item, index) => (
                  <AudioItem
                    key={index}
                    id={item.id}
                    data-type={'voi'}
                    data={item}
                    tabIndex={0}
                    onClick={handleShowPopup}
                    onKeyDown={handleKeyDownShowPopup}
                  />
                ))}
              </ul>
              <ViewMoreButton
                onClick={() => {
                  generateRouteWithParams('/search/audio')
                }}
              />
            </section>
          )}
        </div>
      </div>
      <div className='addition_info_side_area'>
        <div>
          <div className='related_search_box'>
            {relatedWord.length !== 0 && (
              <>
                <p>{t('pages.SearchResultAll.RelatedSearchWord')}</p>
                <RelatedWord.RelatedWordUl>
                  {relatedWord.map((word, index) => (
                    <li key={index}>
                      <RelatedWord word={word} />
                    </li>
                  ))}
                </RelatedWord.RelatedWordUl>
              </>
            )}
            {relatedWord.length === 0 && (
              <>
                <p>{t('pages.SearchResultAll.RecentlySearchWord')}</p>
                <RelatedWord.RelatedWordUl>
                  {RecentlyWord?.map((word, index) => {
                    if (word.word === '') return null
                    else
                      return (
                        <li key={index}>
                          <RelatedWord word={word.word} />
                        </li>
                      )
                  })}
                </RelatedWord.RelatedWordUl>
              </>
            )}
          </div>

          <Chat />
        </div>
      </div>

      <ModalPortal id={'modal_potal'}>
        {detailData ? <Popup type={detailDataType} /> : <PopupLoading />}
      </ModalPortal>
    </Container>
  )
}
