import { useEffect, useState } from 'react'

import DateRangePicker from '../common/DateRangePick/DateRangePicker'

import { addCommaToNumber } from '@/functions'

import styled from '@emotion/styled'
// import { useSelector } from '@/store'
import { useTranslation } from 'react-i18next'

interface Props {
  /** 검색 결과의 전체 length 값 */
  count: number
}

export function SearchResultNumberBox({ count }: Props): JSX.Element {
  /* ------------------------------- redux state ------------------------------ */
  // const { searchQuery } = useSelector((state) => state.search)

  /* -------------------------------- variables ------------------------------- */
  const [showDateInputs, setShowDateInputs] = useState(sessionStorage.getItem('detail') === 'true')

  useEffect(() => {
    sessionStorage.setItem('detail', showDateInputs.toString())
  }, [showDateInputs])

  /* -------------------------------- functions ------------------------------- */
  function openCloseHandler() {
    setShowDateInputs((prev) => !prev)
  }

  const { t } = useTranslation()

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>
          {t('components.SearchResultNumberBox.TotalResult1')}
          <strong>{addCommaToNumber(count)}</strong>
          {t('components.SearchResultNumberBox.TotalResult2')}
        </span>
        <DetailSearchBtn onClick={openCloseHandler}>
          {t('components.SearchResultNumberBox.AdvancedSearch')}
        </DetailSearchBtn>
      </div>

      <hr />

      {/* 상세 검색 */}
      {showDateInputs ? <DateRangePicker openCloseHandler={openCloseHandler} /> : null}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 18px 0 17px 0;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4rem;

  strong {
    font-weight: 700;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: rgba(225, 225, 225, 1);
    margin-top: 0.6rem;
    border: none;
  }
`

const DetailSearchBtn = styled.button`
  padding: 0.9rem 1.3rem;
  border-radius: 0.6rem;
  background: #f0f2f8;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 144%;
`
