import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 1000;
  height: 1px;

  a {
    position: absolute;
    top: -999px;
    padding: 5px 10px;
    font-weight: 700;
    color: #fff;
    background: #333;
    text-decoration: none;
    white-space: nowrap;
  }

  a:focus {
    top: 0;
  }
`

export function A11yDirection() {
  const { t } = useTranslation()

  return (
    <Container>
      <a href='#mainContent'>{t('components.A11yDirection.Content')}</a>
      <a href='#gnbContent'>{t('components.A11yDirection.Menu')}</a>
    </Container>
  )
}
