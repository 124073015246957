/* eslint camelcase: 0 */

import React from 'react'
import * as S from './Pagination.styled'
import _Pagination from 'rc-pagination'
import { useTranslation } from 'react-i18next'

interface PaginationProps {
  currentPage: number
  setCurrent(page: number): void
  perPage: number
  totalRecordCount: number
}

export function Pagination({
  currentPage,
  setCurrent,
  perPage,
  totalRecordCount,
}: PaginationProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <_Pagination
        current={currentPage}
        onChange={(current) => {
          setCurrent(current)
        }}
        pageSize={perPage}
        total={totalRecordCount}
        itemRender={(current, type, element) => {
          if (type === 'prev') {
            return (
              <button tabIndex={1} type='button'>
                <span className='a11y'>prev</span>
              </button>
            )
          }
          if (type === 'next') {
            return (
              <button tabIndex={1} type='button'>
                <span className='a11y'>next</span>
              </button>
            )
          }
          if (type === 'jump-prev') {
            return (
              <button tabIndex={1} type='button'>
                ...
              </button>
            )
          }
          if (type === 'jump-next') {
            return (
              <button tabIndex={1} type='button'>
                ...
              </button>
            )
          }
          if (type === 'page') {
            return (
              <button tabIndex={1} type='button'>
                {current}
              </button>
            )
          }
          return element
        }}
        showPrevNextJumpers={true}
        locale={{
          // Options.jsx
          items_per_page: t('components.Pagination.ItemsPerPage'),
          jump_to: t('components.Pagination.JumpTo'),
          jump_to_confirm: t('components.Pagination.JumpToConfirm'),
          page: t('components.Pagination.Page'),
          // Pagination.jsx
          prev_page: t('components.Pagination.PrevPage'),
          next_page: t('components.Pagination.NextPage'),
          prev_5: t('components.Pagination.Prev5'),
          next_5: t('components.Pagination.Next5'),
          prev_3: t('components.Pagination.Prev3'),
          next_3: t('components.Pagination.Next3'),
        }}
      ></_Pagination>
    </S.Wrapper>
  )
}
