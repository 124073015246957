import React from 'react'
import { useSearchParams } from 'react-router-dom'

import * as S from './SearchNoResult.styled'
import { useTranslation } from 'react-i18next'

interface SearchNoResultProps {
  sectionClassName: 'doc' | 'img' | 'movie' | 'voice' | 'all'
}

export function SearchNoResult({ sectionClassName }: SearchNoResultProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  return (
    <S.Container className={`${sectionClassName}`}>
      <h2>
        {t('components.SearchNoResult.NoResultsFront')}
        <strong>{searchParams.get('keyword')}</strong>
        {t('components.SearchNoResult.NoResultsBack')}
      </h2>
      <ul>
        <li>{t('components.SearchNoResult.Detail1')}</li>
        <li>{t('components.SearchNoResult.Detail2')}</li>
        <li>{t('components.SearchNoResult.Detail3')}</li>
        <li>{t('components.SearchNoResult.Detail4')}</li>
        <li>{t('components.SearchNoResult.Detail5')}</li>
      </ul>
    </S.Container>
  )
}
