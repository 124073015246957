import React from 'react'
import * as S from './404.styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function Page404(): JSX.Element {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <div className='popup-404'>
        <h2>404</h2>
        <h3>{t('pages.404.Detail1')}</h3>
        <p>{t('pages.404.Detail2')}</p>
        <Link to='/'>{t('pages.404.ToMain')}</Link>
      </div>
    </S.Wrapper>
  )
}
