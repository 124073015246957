import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from '@/store'
import { imagesSagaActions } from '@/store/saga/imagesSaga'
import { imagesActions } from '@/store/images'
import { searchSagaActions } from '@/store/saga/searchSaga'

import { MasonryGrid } from '@egjs/react-grid'

import {
  SearchResultNumberBox,
  ViewMoreButton,
  ProgressiveImg,
  // SectionTitle,
  SearchNoResult,
  Loading,
  Image,
  PopupLoading,
} from '@/components'

import styled from '@emotion/styled'
import { CommonLayout } from '@/theme'

import useModal from '@/hooks/useModal'
import useObserver from '@/hooks/useResizeObserber'
import { parseSearchParams } from '@/functions/parseSearchParams'

const Container = styled(CommonLayout)`
  min-height: calc(100vh - 317px);

  .search_result_img_wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  /* 검색 결과 영역 */
  .search_result_inner {
    max-width: 1200px;
    width: 100%;
    /* padding-top: 30px; */
    padding-bottom: 100px;
  }

  .img_grid_wrapper {
    & + .btn_box {
      margin-top: 40px;
    }
  }

  .grid_item {
    max-width: 180px;
    width: 100%;
  }

  .imgae_masonry {
    display: flex;
    column-gap: 24px;
    width: auto;
  }

  .imgae_masonry__column {
    position: relative;
    width: 180px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    figure {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:focus::after,
    &:hover::after {
      cursor: pointer;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }

  .btn_box {
    width: 100%;
    text-align: center;

    .bth_viewMoer {
      width: min(100%, 792px);
    }
  }
`

export function SearchResultImg() {
  /** router */
  const [searchParams] = useSearchParams()

  /** redux */
  const dispatch = useDispatch()
  const { allImagesDataCount, images, isEnd } = useSelector((state) => state.images)
  const { detailData } = useSelector((state) => state.search)

  /** ref */
  const imgItemRef = useRef<HTMLDivElement | null>(null)
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  /** hooks */
  const { ModalPortal, openModal } = useModal(targetRef)

  /** 팝업 오픈 */
  const handleShowPopup = useCallback(
    (e: React.MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLElement
      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'img' }))
    },
    [dispatch, openModal],
  )

  const handleKeyDownShowPopup = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      const currentTarget = e.currentTarget as HTMLElement

      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'img' }))
    },
    [dispatch, openModal],
  )

  /** 결과 더보기 버튼 */
  const handleMoreImageData = useCallback(() => {
    dispatch(imagesSagaActions.getNextImagesSagaAction())
  }, [dispatch])

  /** resize observer */
  const [columnCount, setColumnCount] = useState(6)

  const handle = useCallback((entries: ResizeObserverEntry[]) => {
    const [entry] = entries

    if (entry.contentRect.width > 1200) setColumnCount(6)
    else if (entry.contentRect.width > 995) setColumnCount(5)
    else if (entry.contentRect.width > 791) setColumnCount(4)
    else if (entry.contentRect.width > 587) setColumnCount(3)
    else if (entry.contentRect.width > 383) setColumnCount(2)
    else setColumnCount(1)
  }, [])

  useObserver({ callback: handle, element: 'root' })

  /**  */
  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {
      dispatch(imagesActions.reset('images'))
    }
  }, [dispatch])

  /** 검색 결과 데이터 요청 */
  useEffect(() => {
    // const keyword = searchParams.get('keyword')
    // const s_event_date = searchParams.get('s_event_date')
    // const e_event_date = searchParams.get('e_event_date')

    // if (keyword === null) return

    // const params: Record<string, string> = {
    //   keyword,
    //   s_event_date: s_event_date ?? '',
    //   e_event_date: e_event_date ?? '',
    // }

    const keyword = searchParams.get('keyword')

    if (keyword === null) return

    const params = { ...parseSearchParams(searchParams), keyword }

    dispatch(imagesSagaActions.getImagesSagaAction(params))
  }, [dispatch, searchParams])

  // 데이터가 없는 경우
  if (!images)
    return (
      <Container>
        <Loading />
      </Container>
    )

  if (allImagesDataCount === 0)
    return (
      <Container>
        <div className='search_result_img_wrapper' style={{ width: '100%' }}>
          <SearchResultNumberBox count={0} />
          <div className='search_result_inner'>
            {/* <div style={{ width: '792px' }}> */}
            <div>
              <SearchNoResult sectionClassName='img' />
            </div>
          </div>
        </div>
      </Container>
    )

  return (
    <Container>
      <div className='search_result_img_wrapper'>
        <div className='search_result_inner'>
          {/* <SectionTitle title='이미지' /> */}
          <SearchResultNumberBox count={allImagesDataCount} />
          <MasonryGrid
            className='img_grid_wrapper'
            column={columnCount}
            gap={24}
            align='stretch'
            useResizeObserver
            observeChildren
          >
            {images.map((img, index) => (
              <div
                className='imgae_masonry__column'
                key={`${index}-${img.id}`}
                id={img.id}
                ref={imgItemRef}
                tabIndex={0}
                onClick={handleShowPopup}
                onKeyDown={handleKeyDownShowPopup}
              >
                <ProgressiveImg
                  id={img.id}
                  src={img.file}
                  placeholderSrc={img.thumbnail}
                  alt={`${searchParams.get('keyword')} 관련 이미지(${index + 1})`}
                  width='100%'
                />
              </div>
            ))}
          </MasonryGrid>
          {!isEnd && (
            <div className='btn_box'>
              <ViewMoreButton
                onClick={() => {
                  handleMoreImageData()
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ModalPortal id={'modal_potal'}>{detailData ? <Image /> : <PopupLoading />}</ModalPortal>
    </Container>
  )
}
