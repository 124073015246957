import { createAction } from 'typesafe-actions'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { RootState } from '..'

import { searchActions } from '../search'
import { videosActions } from '../videos'
import { videosDataType, videosType } from '@/types/videosType'

import VideosService from '@/services/VideosService'
import { parseSortItem } from '@/functions/parseSortItem'

const prefix = 'videos'

// 비디오 목록 조회
const getVideosSagaAction = createAction(`${prefix}/GET_VIDEOS_SAGA_ACTION`)<
  Record<string, string>
>()

function* getVideosSaga({ payload }: ReturnType<typeof getVideosSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus } = yield select(
    (state: RootState) => state.search,
  )

  const [sort_field, sort_ad] = parseSortItem()

  const videos: videosDataType[] = yield select((state: RootState) => state.videos)
  try {
    if (videos.length !== 0) yield put(videosActions.reset('videos'))

    const { keyword, s_event_date, e_event_date, data_src_l_cd, data_src_m_cd, data_src_s_cd } =
      payload

    const { data }: { data: videosType } = yield call(VideosService.getVideos, {
      keyword,
      s_event_date,
      e_event_date,
      countPerPage: 12,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
      sort_field,
      sort_ad,
      data_src_l_cd,
      data_src_m_cd,
      data_src_s_cd,
    })

    yield put(videosActions.getVideos(data))
    yield put(searchActions.setSearchQuery(payload))
  } catch (e) {
    yield put(videosActions.error(e))
  }
}

// 비디오 페이지네이션
const getNextVideosSagaAction = createAction(`${prefix}/GET_NEXT_VIDEOS_SAGA_ACTION`)<{
  page: number
  countPerPage: number
}>()

function* getNextVideosSaga({ payload }: ReturnType<typeof getNextVideosSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus, searchQuery } = yield select(
    (state: RootState) => state.search,
  )

  try {
    const { page, countPerPage } = payload

    const { data } = yield call(VideosService.getVideos, {
      ...searchQuery,
      paging: page,
      countPerPage: countPerPage,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
    })

    yield put(videosActions.getNextVideosData({ response: data, paging: page }))
  } catch (e) {
    yield put(videosActions.error(e))
  }
}

export const videosSagaActions = {
  getVideosSagaAction,
  getNextVideosSagaAction,
}

export default function* videosSaga() {
  yield takeLatest(getVideosSagaAction, getVideosSaga)
  yield takeLatest(getNextVideosSagaAction, getNextVideosSaga)
}
