import React, { useState, useEffect, useRef } from 'react'
import * as S from './RecentlySearched.styled'
import { db, Keyword } from '@/db/RecentlySearchedKeywordDB'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function RecentlySearched(): JSX.Element {
  const [keywords, setKeywords] = useState<Keyword[]>([])
  const ulRef = useRef<HTMLUListElement | null>(null)
  const liRefs = useRef<HTMLLIElement[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    db.getAll(setKeywords)
  }, [])

  useEffect(() => {
    if (!ulRef.current) return
    if (!liRefs.current) return
    setTimeout(() => {
      liRefs.current.forEach((li) => {
        if (!ulRef.current) return
        li.classList.remove('hidden')
        if (ulRef.current.offsetHeight + ulRef.current.offsetTop <= li.offsetTop) {
          li.classList.add('hidden')
        }
        li.classList.remove('invisible')
      })
    }, 300)
  }, [keywords, ulRef, liRefs])

  const { t } = useTranslation()

  return keywords.length ? (
    <S.Wrapper>
      <S.HeadingWrapper>
        <h3>{t('components.RecentlySearched.RecentlySearchWord')}</h3>
        <button
          type='button'
          onClick={() => {
            db.delAll()
            setKeywords([])
          }}
        >
          {t('components.RecentlySearched.RemoveAll')}
        </button>
      </S.HeadingWrapper>
      <S.Ul ref={ulRef}>
        {keywords?.map(({ word }) => {
          return (
            <S.Li
              className='invisible'
              key={word}
              ref={(el) => {
                if (!el) return
                liRefs.current = [...liRefs.current, el]
              }}
            >
              <S.Word
                onClick={() => {
                  db.add(word)
                  navigate(`/search?keyword=${word}`)
                }}
                type='button'
                title={word}
                className='word'
              >
                {word}
              </S.Word>
              <S.Del
                type='button'
                className='del'
                onClick={() => {
                  db.del(word)
                  db.getAll(setKeywords)
                }}
              >
                <span className='a11y'>
                  {t('components.RecentlySearched.RemoveWordFront')}
                  {word}
                  {t('components.RecentlySearched.RemoveWordBack')}
                </span>
              </S.Del>
            </S.Li>
          )
        })}
      </S.Ul>
    </S.Wrapper>
  ) : (
    <></>
  )
}
