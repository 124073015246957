export const parseSortItem = () => {
  if (!sessionStorage.getItem('sortitem')) {
    sessionStorage.setItem('sortitem', 'score_desc')
  }

  const sortItem = sessionStorage.getItem('sortitem')
  const [sort_field, sort_ad] = sortItem ? sortItem.split('_') : ['', '']

  switch (sort_field) {
    case 'pub':
      return ['event_date', sort_ad]
    case 'col':
      return ['col_dt', sort_ad]
    case 'score':
      return ['_score', sort_ad]
    default:
      return ['_score', 'desc']
  }
}
