import styled from '@emotion/styled'
import bg from '../../assets/images/bg.png'

export const Container = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
`

export const Main = styled.main`
  min-height: calc(100vh - 99px);
`

export const MainTitleWrapper = styled.div`
  margin: 0 auto 80px;
  padding-top: 10vw;

  h1 {
    font-family: 'Noto Sans KR';
    font-weight: 900;
    font-size: 8rem;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.03em;

    span {
      font-weight: 700;
      line-height: 110%;
      font-size: 6.2rem;
      letter-spacing: -0.02em;
      color: #0f52ff;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1920px) {
    padding-top: 14.81vmin;
    margin-bottom: 6.7vmin;

    h1 {
      font-size: 6.2vmin;
      line-height: 109%;

      span {
        font-size: 4.8vmin;
        line-height: 109%;
      }
    }
  }
`

export const HomeHeaderContainer = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  gap: 16px;
`

export const ChatShotcutLink = styled.div`
  height: 40px;
  white-space: nowrap;
  z-index: 10000;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 16px;
    border: 1px solid #0f52ff;
    border-radius: 0.8rem;

    font-size: 1.4rem;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: -0.01em;
    color: #0f52ff;

    &:hover {
      background-color: rgba(15, 82, 255, 0.1);
    }
  }
`

export const KeycloakLoginLink = styled.div`
  height: 40px;
  white-space: nowrap;
  z-index: 10000;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 16px;
    border: 1px solid #0f52ff;
    border-radius: 0.8rem;
    background: none; /* 버튼의 기본 배경 제거 */
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: -0.01em;
    color: #0f52ff;
    cursor: pointer; /* 버튼 커서 스타일 */

    &:hover {
      background-color: rgba(15, 82, 255, 0.1);
    }

    &:focus {
      outline: none; /* 버튼 포커스 아웃라인 제거 */
    }
  }
`
