import axios from '.'
import i18n from '@/lang'

export default class AudiossService {
  /** 음성 목록 조회 */
  static async getAudios(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
    sort_field: string
    sort_ad: string
    data_src_l_cd: string
    data_src_m_cd: string
    data_src_s_cd: string
  }) {
    return await axios.get('/audios/', {
      params: { ...params, user_language: i18n.language },
    })
  }

  /** 음성 상세 조회 */
  static async getAudioDetail(audioId: string, searchKeyword: string) {
    return await axios.get(
      `/audios/${audioId}?keyword=${searchKeyword}&user_language=${i18n.language}`,
    )
  }
}
