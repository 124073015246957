import { addDays, format, formatDate, subDays } from 'date-fns'
import { useCallback, useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import * as Calendar from '../Calendar'

import styled from '@emotion/styled'
import { ReactComponent as CalendarIcon } from '@/assets/svg/calendar.svg'
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg'
import { ReactComponent as CancelIcon } from '@/assets/svg/cancel.svg'
import { useDispatch } from 'react-redux'
import { searchActions } from '@/store/search'
import { useSelector } from '@/store'
import { useTranslation } from 'react-i18next'
import data_src from './data_src.json'
import i18n from '@/lang'
// import { A11yHidden } from '../A11yHidden'

// 날짜 범위 선택 및 검색 버튼을 포함하는 컴포넌트
type DateRangePickerProps = {
  openCloseHandler: () => void
}

const DateRangePicker = ({ openCloseHandler }: DateRangePickerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const { searchQuery } = useSelector((state) => state.search)

  // ==================== 날짜 선택
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const startDate = searchParams.get('s_event_date')

    return startDate ? new Date(startDate) : null
  })
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const endDate = searchParams.get('e_event_date')

    return endDate ? new Date(endDate) : null
  })

  /** 정렬 기준 설정 */
  if (!sessionStorage.getItem('sortitem')) {
    sessionStorage.setItem('sortitem', 'score_desc')
  }

  const [sortItem, setSortItem] = useState(() => sessionStorage.getItem('sortitem'))

  /** 시작일시 선택 function */
  const onSelectStartDate = useCallback(
    (date: Date | null): void => {
      setStartDate(date)
      dispatch(
        searchActions.setSearchQuery({
          // keyword,
          s_event_date: date ? format(date, 'yyyy-MM-dd') : '',
        }),
      )
    },
    [dispatch],
  )

  /** 종료일시 선택 function */
  const onSelectEndDate = useCallback(
    (date: Date | null): void => {
      setEndDate(date)

      dispatch(
        searchActions.setSearchQuery({
          // keyword,
          e_event_date: date ? format(date, 'yyyy-MM-dd') : '',
        }),
      )
    },
    [dispatch],
  )

  // 필터 설정 부분
  // 동적으로 rdb 값 읽어서 options 가져올 수 있게 변경 필요
  const [dataSrcL, setDataSrcL] = useState(searchParams.get('data_src_l_cd') ?? '')
  const [dataSrcM, setDataSrcM] = useState(searchParams.get('data_src_m_cd') ?? '')
  const [dataSrcS, setDataSrcS] = useState(searchParams.get('data_src_s_cd') ?? '')

  const l_options = i18n.language.includes('ko')
    ? data_src.ko.data_src_l_cd
    : data_src.en.data_src_l_cd
  const m_options = i18n.language.includes('ko')
    ? data_src.ko.data_src_m_cd
    : data_src.en.data_src_m_cd
  const s_options = i18n.language.includes('ko')
    ? data_src.ko.data_src_s_cd
    : data_src.en.data_src_s_cd

  const filteredMOptions = useMemo(() => {
    if (dataSrcL === 'C001') {
      return Object.entries(m_options)
        .filter(([key]) => key.startsWith('2'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcL === 'C003') {
      return Object.entries(m_options)
        .filter(([key]) => key.startsWith('3'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcL === 'C004') {
      return Object.entries(m_options)
        .filter(([key]) => key.startsWith('4'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcL === 'C999') {
      return Object.entries(m_options)
        .filter(([key]) => key.startsWith('9'))
        .map(([key, value]) => ({ key, value }))
    }
    // 다른 dataSrcL 값에 대해 전체 m_options 반환
    return Object.entries(m_options).map(([key, value]) => ({ key, value }))
  }, [dataSrcL, m_options])

  const filteredSOptions = useMemo(() => {
    if (dataSrcM === '202') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('22'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '203') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('23'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '204') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('24'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '205') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('25'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '206') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('26'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '207') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('27'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '208') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('28'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '29') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('29'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '301') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('31'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '302') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('32'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '303') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('33'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '304') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('34'))
        .map(([key, value]) => ({ key, value }))
    } else if (dataSrcM === '999') {
      return Object.entries(s_options)
        .filter(([key]) => key.startsWith('99'))
        .map(([key, value]) => ({ key, value }))
    }
    // 다른 dataSrcL 값에 대해 전체 m_options 반환
    return Object.entries(s_options).map(([key, value]) => ({ key, value }))
  }, [dataSrcM, s_options])

  const onSelectFilter = useCallback(
    (src: string, event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value
      switch (src) {
        case 'data_src_l_cd':
          setDataSrcL(value)
          setDataSrcM('')
          setDataSrcS('')
          dispatch(
            searchActions.setSearchQuery({
              data_src_l_cd: value ?? '',
            }),
          )
          break
        case 'data_src_m_cd':
          setDataSrcM(value)
          setDataSrcS('')
          dispatch(
            searchActions.setSearchQuery({
              data_src_m_cd: value ?? '',
            }),
          )
          break
        case 'data_src_s_cd':
          setDataSrcS(value)
          dispatch(
            searchActions.setSearchQuery({
              data_src_s_cd: value ?? '',
            }),
          )
          break
      }
    },
    [dispatch],
  )

  const handleSearchClick = useCallback(() => {
    // const keyword = searchParams.get('keyword') as string
    const { keyword } = searchQuery

    const params: Record<string, string> = {
      keyword,
    }

    const tmp = params

    if (startDate && !endDate) {
      params['s_event_date'] = formatDate(startDate, 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(new Date(), 'yyyy-MM-dd')
    }

    if (!startDate && endDate) {
      params['s_event_date'] = formatDate('1900-01-01', 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(endDate, 'yyyy-MM-dd')
    }

    if (startDate && endDate) {
      params['s_event_date'] = formatDate(startDate, 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(endDate, 'yyyy-MM-dd')
    }

    if (dataSrcL) params['data_src_l_cd'] = dataSrcL
    if (dataSrcM) params['data_src_m_cd'] = dataSrcM
    if (dataSrcS) params['data_src_s_cd'] = dataSrcS

    setSearchParams(params)

    if (tmp === params) {
      window.location.reload()
    }
  }, [endDate, searchQuery, setSearchParams, startDate, dataSrcL, dataSrcM, dataSrcS])

  //
  const resetHandler = useCallback(() => {
    setStartDate(null)
    setEndDate(null)

    setDataSrcL('')
    setDataSrcM('')
    setDataSrcS('')

    setSortItem('score_desc')
    sessionStorage.setItem('sortitem', 'score_desc')

    const keyword = searchParams.get('keyword') as string

    dispatch(
      searchActions.setSearchQuery({
        keyword,
        s_event_date: '',
        e_event_date: '',
        data_src_l_cd: '',
        data_src_m_cd: '',
        data_src_s_cd: '',
      }),
    )
  }, [dispatch, searchParams])

  const { t } = useTranslation()

  const handleSortItem = (value: string) => {
    setSortItem(value)
    sessionStorage.setItem('sortitem', value)
  }

  return (
    <>
      {/** 검색 정렬 선택 */}
      <FilterSort className='css-1maac14'>
        <span className='title'>{t('components.DateRangePicker.Sort')}</span>
        <div>
          <button
            type='button'
            className={sortItem?.includes('score') ? 'selected-btn' : 'sort-btn'}
            onClick={() =>
              sortItem?.includes('score')
                ? sortItem?.includes('desc')
                  ? handleSortItem('score_asc')
                  : handleSortItem('score_desc')
                : handleSortItem('score_desc')
            }
          >
            {t('components.DateRangePicker.Score')}
            {sortItem?.includes('score') ? (sortItem?.includes('desc') ? ' ▼' : ' ▲') : ''}
          </button>
          <button
            type='button'
            className={sortItem?.includes('pub') ? 'selected-btn' : 'sort-btn'}
            onClick={() =>
              sortItem?.includes('pub')
                ? sortItem?.includes('desc')
                  ? handleSortItem('pub_asc')
                  : handleSortItem('pub_desc')
                : handleSortItem('pub_desc')
            }
          >
            {t('components.DateRangePicker.Pub')}
            {sortItem?.includes('pub') ? (sortItem?.includes('desc') ? ' ▼' : ' ▲') : ''}
          </button>
          <button
            type='button'
            className={sortItem?.includes('col') ? 'selected-btn' : 'sort-btn'}
            onClick={() =>
              sortItem?.includes('col')
                ? sortItem?.includes('desc')
                  ? handleSortItem('col_asc')
                  : handleSortItem('col_desc')
                : handleSortItem('col_desc')
            }
          >
            {t('components.DateRangePicker.Col')}
            {sortItem?.includes('col') ? (sortItem?.includes('desc') ? ' ▼' : ' ▲') : ''}
          </button>
        </div>

        {/** 출처로 분류해서 검색 */}
        {/* <A11yHidden> */}
        <FilterBox className='css-1maac14'>
          <span className='title'>{t('components.DateRangePicker.Src')}</span>
          <div>
            <select
              id='data_src_l_cd'
              className='filter-box'
              value={dataSrcL ?? ''}
              onChange={(event) => onSelectFilter('data_src_l_cd', event)}
            >
              <option value=''></option>
              {Object.entries(l_options).map(
                ([key, value]) =>
                  value !== '' && (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ),
              )}
            </select>
            <select
              id='data_src_m_cd'
              className='filter-box'
              value={dataSrcM ?? ''}
              onChange={(event) => onSelectFilter('data_src_m_cd', event)}
              disabled={!dataSrcL}
            >
              {/* <option value=''></option>
              {Object.entries(m_options).map(
                ([key, value]) =>
                  value !== '' && (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ),
              )} */}
              <option value=''></option>
              {filteredMOptions.map(({ key, value }) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
            <select
              id='data_src_s_cd'
              className='filter-box'
              value={dataSrcS ?? ''}
              onChange={(event) => onSelectFilter('data_src_s_cd', event)}
              disabled={!dataSrcM}
            >
              {/* <option value=''></option>
              {Object.entries(s_options).map(
                ([key, value]) =>
                  value !== '' && (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ),
              )} */}
              <option value=''></option>
              {filteredSOptions.map(({ key, value }) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </FilterBox>
        {/* </A11yHidden> */}
      </FilterSort>

      {/** 기간 검색 */}
      <ContainerDateRangePicker className='date-range-picker'>
        <span className='title'>{t('components.DateRangePicker.Date')}</span>
        <button type='button' className='close-btn' onClick={openCloseHandler}>
          <CloseIcon />
        </button>
        <div className='date-input-container'>
          {/* e_ */}
          <WrapperCalendar className='wrapper-calendar'>
            <Calendar.Root style={{ flexGrow: 1 }}>
              <Calendar.DatePicker
                {...{
                  selected: startDate,
                  onChange: onSelectStartDate,
                  maxDate: endDate ? subDays(new Date(endDate), 0) : new Date(),
                  customInput: (
                    <CustomCalendarInput>
                      <CalendarIcon />
                      <span>{startDate ? format(startDate, 'yyyy.MM.dd') : ''}</span>
                    </CustomCalendarInput>
                  ),
                  calendarContainer: (props) => <Calendar.Container {...props} />,
                }}
              />
            </Calendar.Root>
            {startDate ? (
              <button
                type='button'
                className='date-reset-btn'
                onClick={() => {
                  setStartDate(null)
                  dispatch(
                    searchActions.setSearchQuery({
                      // keyword,
                      s_event_date: '',
                    }),
                  )
                }}
              >
                <CancelIcon />
              </button>
            ) : null}
          </WrapperCalendar>

          <span>~</span>

          <WrapperCalendar className='wrapper-calendar'>
            <Calendar.Root style={{ flexGrow: 1 }}>
              <Calendar.DatePicker
                {...{
                  selected: endDate,
                  onChange: onSelectEndDate,
                  minDate: startDate ? addDays(new Date(startDate), 0) : undefined,
                  maxDate: new Date(),
                  customInput: (
                    <CustomCalendarInput>
                      <CalendarIcon />
                      <span>{endDate ? format(endDate, 'yyyy.MM.dd') : ''}</span>
                    </CustomCalendarInput>
                  ),
                  calendarContainer: (props) => <Calendar.Container {...props} />,
                }}
              />
            </Calendar.Root>
            {endDate ? (
              <button
                type='button'
                className='date-reset-btn'
                onClick={() => {
                  setEndDate(null)
                  dispatch(
                    searchActions.setSearchQuery({
                      e_event_date: '',
                    }),
                  )
                }}
              >
                <CancelIcon />
              </button>
            ) : null}
          </WrapperCalendar>
        </div>
        <WrapperBtn>
          <ResetButton className='reset-button' onClick={resetHandler}>
            {t('components.DateRangePicker.Reset')}
          </ResetButton>
          <SearchButton className='search-button' onClick={handleSearchClick}>
            {t('components.DateRangePicker.Search')}
          </SearchButton>
        </WrapperBtn>
      </ContainerDateRangePicker>
    </>
  )
}

export default DateRangePicker

const ContainerDateRangePicker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  position: relative;
  background-color: #fff;
  margin-top: 2.5rem;
  padding: 2rem;
  border-radius: 0.6rem;

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 100%;
  }

  .close-btn {
    position: absolute;
    top: 1.1rem;
    right: 1.3rem;

    padding: 0.6rem;
    border-radius: 0.6rem;

    :hover {
      background-color: #f0f2f8;
    }
  }

  .date-input-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 0.8rem;
  }
`

const WrapperCalendar = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  width: 50%;
  height: 4.4rem;

  .date-reset-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    svg {
      color: #acb1c0;
    }
  }

  &:nth-of-type(1) {
    padding-left: 1rem;
    padding-right: 2.4rem;
  }

  &:nth-of-type(2) {
    padding-right: 3.4rem;

    .date-reset-btn {
      right: 1rem;
    }
  }
`

const CustomCalendarInput = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  cursor: pointer;

  span {
    width: 100%;
  }
`

const WrapperBtn = styled.div`
  text-align: end;

  button + button {
    margin-left: 0.6rem;
  }
`

const SearchButton = styled.button`
  width: fit-content;
  padding: 0.9rem 1.3rem;
  border-radius: 0.6rem;

  background: #0075e1;
  color: #fff;

  font-size: 1.4rem;
  font-weight: 700;
  line-height: 144%;

  :hover {
    background-color: #053ba4;
  }
`
const ResetButton = styled.button`
  width: fit-content;
  padding: 0.9rem 1.3rem;
  border: 1px solid #5a6073;
  border-radius: 0.6rem;

  background: #fff;
  color: #5a6073;

  font-size: 1.4rem;
  font-weight: 700;
  line-height: 144%;

  :hover {
    background-color: #f0f2f8;
  }
`

const FilterSort = styled.div`
  display: flex;
  gap: 2rem;

  position: relative;
  background-color: #fff;
  margin-top: 2.5rem;
  padding: 2rem;
  border-radius: 0.6rem;

  .sort-btn {
    width: 72px;

    :hover {
      background-color: #f0f2f8;
    }
  }

  .selected-btn {
    font-weight: bold;
    width: 72px;

    :hover {
      background-color: #f0f2f8;
    }
  }

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 5px;
  }
`

const FilterBox = styled.div`
  position: absolute;
  right: 2rem;
  gap: 2rem;

  .filter-box {
    margin: 0 1rem;
    max-width: 108px;
  }

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 15px;
  }
`
