import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import * as S from './Popup.styled'
import { css } from '@emotion/css'

import { ReactComponent as Print } from '@/assets/svg/print.svg'
import { ReactComponent as Download } from '@/assets/svg/file-download.svg'
import { ReactComponent as LinkExternal } from '@/assets/svg/link-external.svg'
import { ReactComponent as ArrowRight } from '@/assets/svg/popup-arrow-right.svg'
import { ReactComponent as ArrowLeft } from '@/assets/svg/popup-arrow-left.svg'

import { imageDetailType } from '@/types/imagesType'
import { hasPopupSource, keywordHighlighting } from '@/functions'

import { useSelector } from '@/store'
import { useDispatch } from 'react-redux'
import { searchSagaActions } from '@/store/saga/searchSaga'

import { Loading } from '../common/Loading'
import { linebreakParse } from '@/functions/linebreakParse'
import useMomodal from '@/hooks/useMomodal'
import { useTranslation } from 'react-i18next'
export function Image() {
  /** router */
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get('keyword')

  /** ref */
  const contentRef = useRef<HTMLDivElement>(null)

  /** useState */
  const [showAllDesc, setShowAllDesc] = useState(false)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)

  /** redux */
  const dispatch = useDispatch()
  const { detailData, loading } = useSelector((state) => state.search)
  const { images } = useSelector((state) => state.images)
  const [targetRef] = useState<HTMLElement | null>(null)
  const { MomodalPortal, openMomodal } = useMomodal(targetRef)

  const { t } = useTranslation()

  const {
    id,
    file,
    data_src_l_cd: dataSrcLCd,
    data_src_m_cd: dataSrcMCd,
    data_src_s_cd: dataSrcSCd,
    source_url: sourceUrl,
    date,
    title,
    content,
    col_dt: colDt,
  } = detailData as imageDetailType

  const handleClickPrevContent = () => {
    images &&
      dispatch(
        searchSagaActions.setDetailDataAction({ id: images[currentItemIndex - 1].id, type: 'img' }),
      )
  }

  const handleClickNextContent = () => {
    images &&
      dispatch(
        searchSagaActions.setDetailDataAction({ id: images[currentItemIndex + 1].id, type: 'img' }),
      )
  }

  const handleShowVllmPopup = useCallback(() => {
    openMomodal()
  }, [openMomodal])

  useEffect(() => {
    images && setCurrentItemIndex(images.findIndex((item) => item.id == id))
  }, [id, images])

  /** 더보기 버튼 */
  const handleShowMoreContent = () => {
    setShowAllDesc(false)
  }

  /** 본문 더보기 버튼 활성화 */
  useLayoutEffect(() => {
    if (contentRef.current && contentRef.current?.clientHeight > 264) {
      setShowAllDesc(true)
    }
  }, [])

  /** 네비게이션 버튼 클릭 시 로딩 화면 */
  if (loading) {
    return (
      <div>
        <S.PopupLayout className='wrap_popup image_popup' $loading={loading}>
          <div
            className={css`
              height: 410px;
              background-color: #111;
            `}
          ></div>
          <div className='popup_info'>
            <div className='info_detail'>
              <dl>
                <div>
                  <dt>{t('components.Popup.Src')}</dt>
                  <dd></dd>
                </div>
                <div>
                  <dt>{t('components.Popup.Pub')}</dt>
                  <dd></dd>
                </div>
                <div>
                  <dt>{t('components.Popup.Col')}</dt>
                  <dd></dd>
                </div>
              </dl>
            </div>
            <div className='info_cotrol'>
              <a href='#none'>
                <Print />
                {t('components.Popup.Print')}
              </a>
              <a href='#none'>
                <Download />
                {t('components.Popup.Download')}
              </a>
            </div>
          </div>
          <div className='popup_content' style={{ height: '338px' }}>
            <Loading
              className={css`
                height: 100%;
              `}
            />
          </div>
        </S.PopupLayout>
      </div>
    )
  }

  return (
    <>
      <style>{S.printStyle}</style>
      <S.PopupLayout className='wrap_popup image_popup' $showContent={showAllDesc}>
        <div className='popup_areaThumb'>
          {location.pathname !== '/search/' && (
            <button type='button' className='btn_navLeft' onClick={handleClickPrevContent}>
              {currentItemIndex !== 0 && <ArrowLeft />}
            </button>
          )}
          <figure className='popup_thumb' title={title}>
            <img src={`${process.env.REACT_APP_BASE_URL}${file}`} alt={title} />
          </figure>
          {location.pathname !== '/search/' && (
            <button className='btn_navRight' onClick={handleClickNextContent}>
              {currentItemIndex + 1 !== images?.length && <ArrowRight />}
            </button>
          )}
        </div>
        <div className='popup_info'>
          <div className='info_detail'>
            <dl>
              <div>
                <dt>출처</dt>
                <dd>
                  <span>{hasPopupSource(dataSrcLCd, dataSrcMCd, dataSrcSCd)}</span>
                  {sourceUrl !== '' && (
                    <a href={sourceUrl} target='_blank' rel='noreferrer noopener'>
                      <LinkExternal />
                    </a>
                  )}
                </dd>
              </div>
              <div>
                <dt>게시일</dt>
                <dd>
                  <span>{date.slice(0, -9).replaceAll('-', '. ')}.</span>
                </dd>
              </div>
              <div>
                <dt>수집일</dt>
                <dd>
                  <span>{colDt.slice(0, -9).replaceAll('-', '. ')}.</span>
                </dd>
              </div>
              {content && (
                <div onClick={handleShowVllmPopup} style={{ cursor: 'pointer' }}>
                  <dt>이미지 요약</dt>
                </div>
              )}
            </dl>
          </div>
          <div className='info_cotrol'>
            <a href='#none' onClick={() => window.print()}>
              <Print />
              인쇄
            </a>
            <a href={`${process.env.REACT_APP_BASE_URL}${file}`}>
              <Download />
              다운로드
            </a>
          </div>
        </div>
        <div className='popup_content'>
          <div className='content_txt' ref={contentRef}>
            {keyword !== null && (
              <>
                {title && <h3>{linebreakParse(keywordHighlighting(title, keyword))}</h3>}
                {/* {content && <p>{linebreakParse(keywordHighlighting(content, keyword))}</p>} */}
              </>
            )}
          </div>
          <button type='button' className='bth_viewMore' onClick={handleShowMoreContent}>
            더보기
          </button>
        </div>
      </S.PopupLayout>
      <MomodalPortal id={'momodal_potal_inner'}>
        {content && <div style={{ color: '#fff', fontSize: 'medium' }}>{content}</div>}
      </MomodalPortal>
    </>
  )
}
