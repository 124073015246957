import { searchActions } from './../search'
import { createAction } from 'typesafe-actions'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import ImagesService from '@/services/ImagesService'
import { imagesActions } from '../images'
import { imagesDataType, imagesType } from '@/types/imagesType'
import { RootState } from '..'
import { imageLensResponseType } from '@/types/imageLensType'
import { parseSortItem } from '@/functions/parseSortItem'

const prefix = 'images'

// 이미지 목록 조회
const getImagesSagaAction = createAction(`${prefix}/GET_IMAGES_SAGA_ACTION`)<
  Record<string, string>
>()

function* getImagesSaga({ payload }: ReturnType<typeof getImagesSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus } = yield select(
    (state: RootState) => state.search,
  )
  const images: imagesDataType[] | null = yield select((state: RootState) => state.images.images)

  const [sort_field, sort_ad] = parseSortItem()

  try {
    if (images && images.length !== 0) yield put(imagesActions.reset('images'))

    const { keyword, s_event_date, e_event_date, data_src_l_cd, data_src_m_cd, data_src_s_cd } =
      payload

    const { data }: { data: imagesType } = yield call(ImagesService.getImages, {
      keyword,
      s_event_date,
      e_event_date,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
      sort_field,
      sort_ad,
      data_src_l_cd,
      data_src_m_cd,
      data_src_s_cd,
    })

    yield put(imagesActions.getImages(data))
    yield put(searchActions.setSearchQuery(payload))
  } catch (e) {
    yield put(imagesActions.error(e))
  }
}

const getNextImagesSagaAction = createAction(`${prefix}/GET_NEXT_IMAGES_SAGA_ACTION`)()

function* getNextImagesSaga() {
  const { pagingNumber } = yield select((state: RootState) => state.images)

  const { orSearchCheckStatus, semanticSearchCheckStatus, searchQuery } = yield select(
    (state: RootState) => state.search,
  )

  try {
    const { data } = yield call(ImagesService.getImages, {
      ...searchQuery,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
      paging: pagingNumber + 1,
    })

    if (data.data.length !== 0) {
      yield put(
        imagesActions.getNextImages({
          data,
          pagingNumber: pagingNumber + 1,
        }),
      )
    }
  } catch (e) {
    yield put(imagesActions.error(e))
  }
}

// 이미지 검색 결과 데이터 저장
const setImagesSagaAction = createAction(`${prefix}/SET_IMAGES_SAGA_ACTION`)<{
  data: imageLensResponseType
}>()

function* setImagesSaga({ payload }: ReturnType<typeof setImagesSagaAction>) {
  try {
    const { data } = payload
    yield put(imagesActions.getImages(data))
  } catch (e) {
    yield put(imagesActions.error(e))
  }
}

export const imagesSagaActions = {
  getImagesSagaAction,
  getNextImagesSagaAction,
  setImagesSagaAction,
}

export default function* imagesSaga() {
  yield takeLatest(getImagesSagaAction, getImagesSaga)
  yield takeLatest(getNextImagesSagaAction, getNextImagesSaga)
  yield takeLatest(setImagesSagaAction, setImagesSaga)
}
