import { useLayer } from '@/hooks'
import { ReactComponent as Logout } from '@/assets/svg/logout.svg'
import { ReactComponent as Opinion } from '@/assets/svg/opinion-bubble.svg'
import styled from '@emotion/styled'
import * as S from '../../pages/Home/Home.styled'
import { useReactKeycloackId } from 'react-keycloak-id'
import { useTranslation } from 'react-i18next'

export function Avatar() {
  const keycloak = useReactKeycloackId()
  const { isShow, handleIsShow, Layer } = useLayer()

  const handleLogin = () => {
    keycloak.login()
  }

  const { t } = useTranslation()

  if (!keycloak.authenticated)
    return (
      <S.KeycloakLoginLink>
        <button onClick={handleLogin}>Login</button>
      </S.KeycloakLoginLink>
    )

  return (
    <Layer>
      <AvatarButton type='button' onClick={() => handleIsShow(true)}>
        <span>{keycloak.tokenParsed?.preferred_username.slice(0, 1)?.toUpperCase() ?? 'U'}</span>
      </AvatarButton>
      {isShow && (
        <AvatarContent>
          <button
            type='button'
            onClick={() => {
              const opinionUrl = process.env.REACT_APP_OPINION_GITLAB_URL
              window.location.href = opinionUrl as string
            }}
          >
            <Opinion />
            <span>{t('components.Avartar.Feedback')}</span>
          </button>
          <button
            type='button'
            onClick={() => {
              keycloak.logout()
            }}
          >
            <Logout />
            <span>{t('components.Avartar.LogOut')}</span>
          </button>
        </AvatarContent>
      )}
    </Layer>
  )
}

const AvatarButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #003fdc;

  width: 40px;
  height: 40px;
  border-radius: 9999px;

  font-size: 19px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #fff;

  box-shadow: 0px 1px 1px 0px #0000001a;
`

const AvatarContent = styled.ul`
  position: absolute;
  top: 108%;
  right: 0;
  width: 200px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 16px 0px #00000040;

  button {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 8px 26px;

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
    }

    &:hover {
      background: #0f52ff33;
    }
  }
`
