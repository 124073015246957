import axios from '.'
import i18n from '@/lang'

export default class ImagesService {
  /** 이미지 목록 조회 */
  static async getImages(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
    sort_field: string
    sort_ad: string
    data_src_l_cd: string
    data_src_m_cd: string
    data_src_s_cd: string
  }) {
    return axios.get('/images/', { params: { ...params, user_language: i18n.language } })
  }

  /** 이미지 상세 조회 */
  static async getImagesDetail(imageId: string, searchKeyword: string) {
    return axios.get(`/images/${imageId}?keyword=${searchKeyword}&user_language=${i18n.language}`)
  }
}
