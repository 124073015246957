import axios from '.'
import i18n from '@/lang'

export default class DocumentsService {
  /** 문서 목록 조회 */
  static async getDocuments(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
    sort_field: string
    sort_ad: string
    data_src_l_cd: string
    data_src_m_cd: string
    data_src_s_cd: string
  }) {
    return await axios.get('/documents/', {
      params: { ...params, user_language: i18n.language },
    })
  }

  /** 문서 상세 조회 */
  static async getDocumentsDetail(documentId: string, searchKeyword: string) {
    return await axios.get(
      `/documents/${documentId}?keyword=${searchKeyword}&user_language=${i18n.language}`,
    )
  }
}
