import styled from '@emotion/styled'
import React, { useState, useEffect } from 'react'
import windowsIcon from '@/assets/images/window-logo.png'
import macIcon from '@/assets/images/apple-logo.png'
import { useTranslation } from 'react-i18next'

type SearchAgentProps = {
  isFloat?: boolean
}

export function SearchAgent({ isFloat = false }: SearchAgentProps): JSX.Element {
  const [os, setOS] = useState<string>('Unknown')

  useEffect(() => {
    const userAgent = window.navigator.userAgent
    if (userAgent.includes('Win')) setOS('win')
    else if (userAgent.includes('Mac')) setOS('mac')
    // else if (userAgent.includes('Linux')) setOS('Linux');
  }, [])

  const getIcon = () => {
    switch (os) {
      case 'win':
        return <img src={windowsIcon} alt='Windows Icon' style={{ marginRight: 8, width: 16 }} />
      case 'mac':
        return <img src={macIcon} alt='Mac Icon' style={{ marginRight: 8, width: 16 }} />
      default:
        return '' // 기본값은 아이콘 없음
    }
  }

  const { t } = useTranslation()

  return (
    <Container $isFloat={isFloat}>
      <a
        href={`${process.env.REACT_APP_SEARCH_AGENT_URL}/agent/archive?os_type=` + os}
        download
        target='_blank'
        rel='noreferrer'
      >
        {getIcon()} {t('components.SearchAgent')}
      </a>
    </Container>
  )
}

export function SearchChatShortCut({ isFloat = false }: SearchAgentProps): JSX.Element {
  return (
    <Container $isFloat={isFloat}>
      <a href={process.env.REACT_APP_T3Q_CHAT_URL}>T3Q.chat</a>
    </Container>
  )
}

const Container = styled.div<{ $isFloat: boolean }>`
  position: ${({ $isFloat }) => ($isFloat ? 'absolute' : 'static')};
  top: 3rem;
  right: 3rem;

  /* width: 126px; */
  height: 40px;
  white-space: nowrap;
  z-index: 10000;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 16px;
    border: 1px solid #0f52ff;
    border-radius: 0.8rem;

    font-size: 1.4rem;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: -0.01em;
    color: #0f52ff;

    &:hover {
      background-color: rgba(15, 82, 255, 0.1);
    }

    &:active {
      background-color: rgba(15, 82, 255, 0.3);
    }
  }
`
