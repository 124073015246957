import React from 'react'
import * as S from './SectionTitle.styled'
import { useTranslation } from 'react-i18next'

interface SectionTitleProps {
  title: '문서' | '이미지' | '동영상' | '음성' | '유사문서'
}

export function SectionTitle({ title }: SectionTitleProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <S.Wrapper title={title} as='h2'>
      <span>{t('components.SectionTitle.' + title)}</span>
    </S.Wrapper>
  )
}
