import React, { useEffect, useState } from 'react'
import * as S from './Home.styled'
import { Footer } from '@/layout/Footer/Footer'
import { SearchBar } from '@/components/SearchBar/SearchBar'
import { RecentlySearched } from '@/components/RecentlySearched/RecentlySearched'

import { useSelector, useDispatch } from 'react-redux'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { RootState } from '@/store'
import { searchActions } from '@/store/search'
import { Avatar, SearchAgent } from '@/components'
import { useTranslation } from 'react-i18next'
// import i18n from '@/lang'

export function Home(): JSX.Element {
  /** useState */
  const [searchText, setSearchText] = useState(
    useSelector((state: RootState) => state.search.searchText),
  )

  // 추후 인기검색어 일자를 직접 입력할 경우 사용 할 state입니다.
  // 일자를 입력하지 않을 경우 자동으로 금일 기준 1주일 기간의 검색어를 가져옵니다.
  // 예, 금일 2022-08-26일 경우 'start_date=2022-08-19&end_date=2022-08-26'으로 keyword 요청 됨
  const [startDate] = useState(null)
  const [endDate] = useState(null)

  /** redux */
  const dispatch = useDispatch()
  const { popularWord } = useSelector((state: RootState) => state.search)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      searchSagaActions.getPopularWordSagaAction({
        startDate,
        endDate,
      }),
    )
  }, [dispatch, endDate, startDate])

  useEffect(() => {
    dispatch(searchSagaActions.searchAcSagaAction(searchText))
    dispatch(searchActions.setSearchText(searchText))
  }, [searchText, dispatch])

  // const changeLanguage = (lang: string) => {
  //   i18n.changeLanguage(lang)
  // }

  return (
    <>
      <S.Container>
        <S.Main>
          <S.MainTitleWrapper>
            <h1>
              <span>{t('pages.Home.title')}</span>
              <br />
              T3Q.search+
            </h1>
          </S.MainTitleWrapper>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            popularWord={popularWord}
          />
          <RecentlySearched />
        </S.Main>
        <Footer />
      </S.Container>
      <S.HomeHeaderContainer>
        {/* <button onClick={() => changeLanguage('ko')}>한국어</button>
        <button onClick={() => changeLanguage('en')}>ENGLISH</button> */}
        <S.ChatShotcutLink>
          <a href={process.env.REACT_APP_T3Q_CHAT_URL}>T3Q.chat</a>
        </S.ChatShotcutLink>
        <SearchAgent />
        <Avatar />
      </S.HomeHeaderContainer>
    </>
  )
}
