import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  DocItem,
  SectionTitle,
  SearchResultNumberBox,
  Pagination,
  Loading,
  SearchNoResult,
  Document,
  PopupLoading,
} from '@/components'

import useModal from '@/hooks/useModal'

import styled from '@emotion/styled'
import { CommonLayout } from '@/theme'

import { useDispatch } from 'react-redux'
import { useSelector } from '@/store'
import { documentsSagaActions } from '@/store/saga/documentsSaga'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { parseSearchParams } from '@/functions/parseSearchParams'

const Container = styled(CommonLayout)`
  min-height: calc(100vh - 317px);

  .search_result_doc_wrapper {
    width: 100%;
  }

  /* 검색 결과 영역 */
  .search_result_inner {
    max-height: calc(100% - 50px);
    height: 100%;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .list_doc {
    margin-bottom: 40px;
  }
`

export function SearchResultDoc() {
  /** router */
  const [searchParams] = useSearchParams()

  /** redux */
  const dispatch = useDispatch()
  const { allDocumentsDataCount, documents } = useSelector((state) => state.documents)
  const { detailData } = useSelector((state) => state.search)

  /** useState */
  const [currentPage, setCurrentPage] = useState(1)

  /** ref */
  const docItemRef = useRef<HTMLLIElement | null>(null)
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  /** hooks */
  const { ModalPortal, openModal } = useModal(targetRef)

  /** 팝업 오픈 */
  const handleShowPopup = useCallback(
    (e: React.MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLElement
      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'doc' }))
    },
    [dispatch, openModal],
  )

  const handleKeyDownShowPopup = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      const currentTarget = e.currentTarget as HTMLElement

      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'doc' }))
    },
    [dispatch, openModal],
  )

  /** 페이지네이션 */
  const handlePagination = useCallback(
    (page: number) => {
      setCurrentPage(page)

      dispatch(documentsSagaActions.getNextDocumentsSagaAction(page))
    },
    [dispatch],
  )

  /** 문서 검색 결과 */
  useEffect(() => {
    // const keyword = searchParams.get('keyword')
    // const s_event_date = searchParams.get('s_event_date')
    // const e_event_date = searchParams.get('e_event_date')

    // if (keyword === null) return

    // const params: Record<string, string> = {
    //   keyword,
    //   s_event_date: s_event_date ?? '',
    //   e_event_date: e_event_date ?? '',
    // }

    const keyword = searchParams.get('keyword')

    if (keyword === null) return

    const params = { ...parseSearchParams(searchParams), keyword }

    dispatch(documentsSagaActions.setDocumentsSagaAction(params))
  }, [dispatch, searchParams])

  // 데이터가 없는 경우
  if (!documents)
    return (
      <Container className='search_result_doc'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: 'calc(100vh - 317px)',
          }}
        >
          <Loading />
        </div>
      </Container>
    )

  if (allDocumentsDataCount === 0)
    return (
      <Container className='search_result_doc'>
        <div
          style={{
            width: '100%',
          }}
        >
          <SearchResultNumberBox count={0} />
          <div className='search_result_inner'>
            <SearchNoResult sectionClassName='all' />
          </div>
        </div>
      </Container>
    )

  return (
    <Container className='search_result_doc'>
      <div className='search_result_doc_wrapper'>
        <SearchResultNumberBox count={allDocumentsDataCount} />

        <div className='search_result_inner'>
          <SectionTitle title='문서' />
          <ul className='list_doc'>
            {documents.map((item) => (
              <DocItem
                ref={docItemRef}
                key={item.id}
                id={item.id}
                dataItem={item}
                tabIndex={0}
                onClick={(e) => handleShowPopup(e)}
                onKeyDown={handleKeyDownShowPopup}
              />
            ))}
          </ul>
          {10 < allDocumentsDataCount && (
            <Pagination
              currentPage={currentPage}
              setCurrent={handlePagination}
              perPage={10}
              totalRecordCount={allDocumentsDataCount}
            />
          )}
        </div>
      </div>
      <ModalPortal id={'modal_potal'}>{detailData ? <Document /> : <PopupLoading />}</ModalPortal>
    </Container>
  )
}
