import React from 'react'

import styled from '@emotion/styled'

import { ReactComponent as Arrow } from '@/assets/svg/feedback-arrow-right.svg'
import { ReactComponent as Memoji } from '@/assets/svg/feedback-memoji.svg'
import { useTranslation } from 'react-i18next'

const FeedbackStyle = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 0.813em 1.563em 0.813em 4.75em;
  font-size: 1.6rem;
  background-color: #0f4ded;
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.2);

  .icon_memoji {
    position: absolute;
    left: 6px;
    bottom: 0;
  }

  span {
    display: inline-block;
    margin-right: 5px;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: -0.03em;
    color: #fff;
  }
`

interface FeedbackProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url: string
}

export function Feedback({ url, ...restProps }: FeedbackProps) {
  const { t } = useTranslation()

  return (
    <FeedbackStyle href={url} target='_branck' rel='noreferrer noopener' {...restProps}>
      <Memoji className='icon_memoji' />
      <span>{t('components.Feedback')}</span>
      <Arrow className='icon_arrow' />
    </FeedbackStyle>
  )
}
