import { ReactComponent as LinkExternal } from '@/assets/svg/link-external.svg'
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg'
import { ReactComponent as ChatIcon } from '@/assets/svg/chat.svg'
import styled from '@emotion/styled'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

export function Chat(): JSX.Element {
  const [searchParams] = useSearchParams()
  const [streamedContent, setStreamedContent] = useState('') // 스트리밍된 데이터를 상태로 관리
  const keyword = searchParams.get('keyword')

  useEffect(() => {
    if (!keyword) return

    setStreamedContent('') // 새로운 검색을 위해 초기화

    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SEARCH_AGENT_URL}/search/chatbot?keyword=${keyword}`,
        // 'http://localhost:9999/api/chatTest/streamTest?keyword=아이유'
      )

      if (!response.ok) {
        console.error('Network response was not ok')
        return
      }

      const reader = response.body?.getReader()
      if (!reader) {
        console.error('Stream not supported')
        return
      }

      const decoder = new TextDecoder('utf-8')
      let accumulatedData = ''
      let done = false

      while (!done) {
        const { done: streamDone, value } = await reader.read()
        done = streamDone

        const chunk = decoder.decode(value, { stream: true })
        accumulatedData += chunk

        const lines = accumulatedData.split('\n')
        accumulatedData = lines.pop() || '' // 남은 데이터를 저장

        for (const line of lines) {
          const trimmedLine = line.trim()

          if (trimmedLine === 'data: [DONE]') {
            done = true
            break
          }

          if (trimmedLine.startsWith('data: ')) {
            try {
              const jsonData = JSON.parse(trimmedLine.replace('data: ', '').trim())
              const delta = jsonData.choices[0]?.delta

              if (!delta || !delta.content) {
                continue
              }

              const content = delta.content
              if (content) {
                // 상태를 통해 데이터를 추가하고 UI를 리렌더링
                setStreamedContent((prevContent) => prevContent + content)
              }
            } catch (error) {
              console.error('Failed to parse JSON:', error, line)
            }
          }
        }
      }
    }

    fetchData()
  }, [keyword])

  return (
    <Container data-query={!!keyword}>
      <div className='chat_title_box'>
        <span>T3Q.chat</span>
        <a href={process.env.REACT_APP_T3Q_CHAT_URL} target='_blank' rel='noreferrer'>
          <LinkExternal />
        </a>
      </div>
      <div className='chat_body_box'>
        <div className='chat_search_query_box'>
          <ArrowRight />
          <pre>{keyword}</pre>
        </div>

        <div className='chat_search_result_box'>
          <ChatIcon />
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {streamedContent} {/* 상태를 사용하여 데이터를 출력 */}
          </pre>
        </div>
      </div>

      <span className='text'></span>
    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;

  &[data-query='false'] {
    .chat_title_box {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .chat_body_box {
      display: none;
    }
  }

  .chat_title_box {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e1e1e1;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      color: #222222;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29px;
      height: 29px;
      border: 1px solid #e1e1e1;
      border-radius: 9999px;
      box-shadow: 0px 0.59375px 0.59375px 0px #0000001a;
    }
  }

  .chat_body_box {
  }

  .chat_search_query_box {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 8px;
    /* align-items: center; */
    margin-bottom: 12px;

    svg {
      color: #0f52ff;
    }

    pre {
      width: calc(100% - 32px);
      height: fit-content;
      /* margin-top: 4px; */
      align-self: center;

      font-size: 14px;
      font-weight: 500;
      line-height: 114%;
      letter-spacing: -0.03em;
      white-space: pre-wrap;
    }
  }

  .chat_search_result_box {
    display: grid;
    grid-template-columns: 18px auto;
    column-gap: 28px;
    width: 100%;

    padding: 24px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;

    pre {
      position: relative;
      /* border-right: 3px solid black; */
      max-width: 234px;
      word-wrap: break-word;
      white-space: pre-wrap;

      font-size: 14px;
      font-weight: 500;
      line-height: 144%;
      letter-spacing: -0.03em;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 3px;
        height: 16px;
        background-color: #000;
        visibility: hidden;
      }

      &[data-isSearching='true'] {
        &:before {
          visibility: visible;
          left: 0;
          animation: blink 0.5s infinite;
        }
      }
    }

    .Typewriter__wrapper {
      font-size: 14px;
      font-weight: 400;
      line-height: 144%;
      letter-spacing: -0.03em;
    }

    @keyframes blink {
      50% {
        background-color: transparent;
      }
    }
  }
`
