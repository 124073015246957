import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import parse from 'html-react-parser'
import { useSearchParams } from 'react-router-dom'

import { audiosDataType } from '@/types/audiosType'

import * as S from './AudioItem.styled'
import { ReactComponent as AudioIcon } from '@/assets/svg/voice.svg'
import { ReactComponent as PlayIcon } from '@/assets/svg/playIcon.svg'
import { convertDuration, hasPopupSource, keywordHighlighting } from '@/functions'
import convertTitle from '@/functions/convertTitle'
import { useTranslation } from 'react-i18next'

interface AudioItemProps extends React.HTMLAttributes<HTMLLIElement> {
  data: audiosDataType
}

export const AudioItem = forwardRef<HTMLLIElement, AudioItemProps>(
  (
    {
      data: {
        title,
        date,
        contents,
        file,
        col_dt: colDt,
        data_src_l_cd: dataSrcLCd,
        data_src_m_cd: dataSrcMCd,
        data_src_s_cd: dataSrcSCd,
        score: score,
      },
      ...restProps
    }: AudioItemProps,
    ref,
  ) => {
    const [duration, setDuration] = useState<number>(0)
    const [searchParams] = useSearchParams()
    const keyword = useMemo(() => searchParams.get('keyword'), [searchParams])
    const { t } = useTranslation()

    useEffect(() => {
      const audio = document.createElement('audio')
      audio.src = `${process.env.REACT_APP_BASE_URL}${file}`

      audio.onloadedmetadata = () => {
        setDuration(audio.duration)
      }
    }, [file])

    // if (keyword == null) return null
    // if (!file) return null

    return (
      <S.AudioItem ref={ref} {...restProps}>
        <S.AudioInfoBox className='info_box'>
          <div className='icon_wrapper'>
            <AudioIcon />
          </div>
          <div className='play_info_box'>
            <span>{convertDuration(Math.floor(duration))}</span>
            <PlayIcon width={30} height={30} />
          </div>
        </S.AudioInfoBox>
        <S.DetailBox className='detail_box'>
          <p role='doc-subtitle'>{convertTitle(title ?? '', 'audio')}</p>
          <span className='contents'>
            {/* <time dateTime={date}>{date.slice(0, -9).replaceAll('-', '. ')}&#46;</time> —&nbsp; */}
            {/* {parse(keywordHighlighting(contents ?? '', keyword))} */}
            {keyword && parse(keywordHighlighting(contents ?? '', keyword))}
          </span>
          <dl className='additional_info'>
            <div>
              <dt>{t('components.Item.Pub')}</dt>
              <dd>
                <span>
                  <time dateTime={date}>{date.slice(0, -9).replaceAll('-', '. ')}&#46;</time>
                </span>
              </dd>
            </div>
            <div>
              <dt>{t('components.Item.Col')}</dt>
              <dd>
                <span>
                  <time dateTime={colDt}>{colDt.slice(0, -9).replaceAll('-', '. ')}&#46;</time>
                </span>
              </dd>
            </div>
            <div>
              <dt>{t('components.Item.Src')}</dt>
              <dd>
                <span>{hasPopupSource(dataSrcLCd, dataSrcMCd, dataSrcSCd)}</span>
              </dd>
            </div>
            {score !== null && score !== undefined && (
              <div>
                <dt>{t('components.Item.Sim')}</dt>
                <dd>
                  <span>{score}</span>
                </dd>
              </div>
            )}
          </dl>
        </S.DetailBox>
      </S.AudioItem>
    )
  },
)

AudioItem.displayName = 'AudioItem'
