import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en'
import ko from './locales/ko'

const getBrowserLanguage = () => {
  const lang = navigator.language ? navigator.language : 'ko-KR'
  return lang.includes('ko') ? 'ko' : 'en'
}

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    ko: ko,
  },
  lng: getBrowserLanguage(),
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
  ns: ['page'],
})

export default i18n
