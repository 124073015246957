import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { searchDataType, searchDetailType, searchType } from '@/types/searchType'

interface initialStateType {
  loading: boolean
  semanticSearchCheckStatus: boolean
  orSearchCheckStatus: boolean
  searchText: string
  acList: string[]
  relatedWord: string[]
  searchQuery: Record<string, string>
  allSearchDataCount: number
  searchData: searchDataType | null
  error?: unknown
  detailData: searchDetailType | null
  detailDataType: string
  detailMomodalData: searchDetailType | null
  popularWord: string[]
}

const initialState: initialStateType = {
  loading: false,
  semanticSearchCheckStatus: false,
  orSearchCheckStatus: false,
  searchText: '',
  acList: [],
  relatedWord: [],
  searchQuery: {},
  allSearchDataCount: 0,
  searchData: null,
  detailData: null,
  detailDataType: '',
  detailMomodalData: null,
  popularWord: [],
}

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSemanticSearchCheck: (state, { payload }: PayloadAction<boolean>) => {
      state.semanticSearchCheckStatus = payload

      return state
    },
    setOrSearchCheck: (state, { payload }: PayloadAction<boolean>) => {
      state.orSearchCheckStatus = payload

      return state
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload

      return state
    },
    setAcList: (state, action: PayloadAction<string[]>) => {
      state.acList = action.payload

      return state
    },
    getRelatedWord: (state, action: PayloadAction<string[]>) => {
      state.relatedWord = action.payload

      return state
    },
    setSearchQuery: (state, action: PayloadAction<Record<string, string>>) => {
      state.searchQuery = { ...state.searchQuery, ...action.payload }

      return state
    },
    getAllData: (state, action: PayloadAction<searchType>) => {
      const { meta, data } = action.payload

      state.allSearchDataCount = meta.count
      state.searchData = data

      return state
    },
    setDetailData: (state, action) => {
      const { data, type } = action.payload

      state.detailData = data
      state.detailDataType = type

      return state
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
      return state
    },
    setPopularWord: (state, action: PayloadAction<string[]>) => {
      state.popularWord = action.payload
      return state
    },
    reset: (state, action: PayloadAction<keyof initialStateType>) => {
      const id = action.payload

      if (id === 'searchData') state[id] = null
      if (id === 'detailData') state[id] = null
      if (id === 'detailMomodalData') state[id] = null

      return state
    },
    error: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload

      return state
    },
  },
})

export const searchActions = { ...search.actions }

export default search
