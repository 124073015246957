export const parseSearchParams = (searchParams: URLSearchParams) => {
  const s_event_date = searchParams.get('s_event_date')
  const e_event_date = searchParams.get('e_event_date')
  const data_src_l_cd = searchParams.get('data_src_l_cd')
  const data_src_m_cd = searchParams.get('data_src_m_cd')
  const data_src_s_cd = searchParams.get('data_src_s_cd')

  const params: Record<string, string> = {
    s_event_date: s_event_date ?? '',
    e_event_date: e_event_date ?? '',
    data_src_l_cd: data_src_l_cd ?? '',
    data_src_m_cd: data_src_m_cd ?? '',
    data_src_s_cd: data_src_s_cd ?? '',
  }

  return params
}
