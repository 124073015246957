import { createAction } from 'typesafe-actions'
import { put, call, takeLatest, debounce, select, CallEffect } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { RootState } from '..'

import { snakeCase } from 'snake-case'

import { searchActions } from '../search'
import { searchType } from '@/types/searchType'

import SearchService from '@/services/SearchService'
import DocumentsService from '@/services/DocumentsService'
import ImagesService from '@/services/ImagesService'
import VideosService from '@/services/VideosService'
import AudiosService from '@/services/AudiosService'
import { parseSortItem } from '@/functions/parseSortItem'

const prefix = 'search'

const searchAcSagaAction = createAction(`${prefix}/SEARCH_AC_SAGA_ACTION`)<string>()
const setSearchTextSagaAction = createAction(`${prefix}/SET_SEARCH_TEXT_SAGA_ACTION`)<string>()
const getSearchAllSagaAction = createAction(`${prefix}/GET_SEARCH_ALL_SAGA_ACTION`)<
  Record<string, string>
>()
const setSemanticSearchCheckAction = createAction(`${prefix}/SET_SEMANTIC_SEARCH_CHECK`)<boolean>()
const setOrSearchCheckAction = createAction(`${prefix}/SET_OR_SEARCH_CHECK`)<boolean>()
const setDetailDataAction = createAction(`${prefix}/SET_DETAIL_DATA_ACTION`)<{
  id: string
  type: string
}>()
const getPopularWordSagaAction = createAction(`${prefix}/GET_POPULAR_WORD_SAGA_ACTION`)<{
  [key: string]: string | null
}>()

function* searchAcSaga({ payload }: ReturnType<typeof searchAcSagaAction>) {
  try {
    const data: string[] = yield call(SearchService.getSearchAC, { keyword: payload })

    yield put(searchActions.setAcList(data))
  } catch (e) {
    yield put(searchActions.error(e))
  }
}

function* setSearchTextSaga({ payload }: ReturnType<typeof setSearchTextSagaAction>) {
  try {
    yield put(searchActions.setSearchText(payload))
  } catch (e) {
    yield put(searchActions.error(e))
  }
}

function* getSearchAllSaga({ payload }: ReturnType<typeof getSearchAllSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus } = yield select(
    (state: RootState) => state.search,
  )

  const [sort_field, sort_ad] = parseSortItem()

  try {
    yield put(searchActions.reset('searchData'))

    const { keyword, s_event_date, e_event_date, data_src_l_cd, data_src_m_cd, data_src_s_cd } =
      payload
    const {
      data: searchData,
    }: {
      data: searchType
    } = yield call(SearchService.getSearchES, {
      keyword,
      s_event_date,
      e_event_date,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
      sort_field,
      sort_ad,
      data_src_l_cd,
      data_src_m_cd,
      data_src_s_cd,
    })

    const {
      data: { data: relatedWord },
    } = yield call(SearchService.getRelatedWord, { keyword: payload.keyword })

    yield put(searchActions.getAllData(searchData))
    yield put(searchActions.getRelatedWord(relatedWord))
    yield put(searchActions.setSearchQuery(payload))
  } catch (e: unknown) {
    yield put(searchActions.error(e))
  }
}

function* setSemanticSearchCheckSaga({ payload }: ReturnType<typeof setSemanticSearchCheckAction>) {
  try {
    yield put(searchActions.setSemanticSearchCheck(payload))
  } catch (e) {
    yield
  }
}

function* setOrSearchCheckSaga({ payload }: ReturnType<typeof setOrSearchCheckAction>) {
  try {
    yield put(searchActions.setOrSearchCheck(payload))
  } catch (e) {
    yield
  }
}

// 팝업 상세 데이터
function* setDetailDataSaga({ payload }: ReturnType<typeof setDetailDataAction>) {
  const { keyword } = yield select((state: RootState) => state.search.searchQuery)

  const filterDetail: {
    [key: string]: (id: string) => CallEffect<AxiosResponse>
  } = {
    doc: (id) => call(DocumentsService.getDocumentsDetail, id, keyword),
    img: (id) => call(ImagesService.getImagesDetail, id, keyword),
    mov: (id) => call(VideosService.getVideoDetails, id, keyword),
    voi: (id) => call(AudiosService.getAudioDetail, id, keyword),
  }

  function filterDetailType(id: string, type: string) {
    return filterDetail[type](id)
  }

  try {
    const { id, type } = payload

    yield put(searchActions.setLoading(true))

    const {
      data: { data },
    } = yield filterDetailType(id, type)

    yield put(searchActions.setLoading(false))
    yield put(searchActions.setDetailData({ data, type }))
  } catch (e) {
    yield put(searchActions.error(e))
  }
}

// 인기 검색어
function* getPopularWordSaga({ payload }: ReturnType<typeof getPopularWordSagaAction>) {
  const popularKeywordPrams: { [key: string]: string | null } = {}

  for (const prop in payload) {
    popularKeywordPrams[snakeCase(prop)] = payload[prop]
  }

  try {
    const {
      data: { data },
    } = yield call(SearchService.getPopularWord, popularKeywordPrams)

    yield put(searchActions.setPopularWord(data))
  } catch (e) {
    yield put(searchActions.error(e))
  }
}

export const searchSagaActions = {
  searchAcSagaAction,
  setSearchTextSagaAction,
  getSearchAllSagaAction,
  setSemanticSearchCheckAction,
  setOrSearchCheckAction,
  setDetailDataAction,
  getPopularWordSagaAction,
}

export default function* searchSaga() {
  yield debounce(300, searchAcSagaAction, searchAcSaga)
  yield takeLatest(setSearchTextSagaAction, setSearchTextSaga)
  yield takeLatest(getSearchAllSagaAction, getSearchAllSaga)
  yield takeLatest(setSemanticSearchCheckAction, setSemanticSearchCheckSaga)
  yield takeLatest(setOrSearchCheckAction, setOrSearchCheckSaga)
  yield takeLatest(setDetailDataAction, setDetailDataSaga)
  yield takeLatest(getPopularWordSagaAction, getPopularWordSaga)
}
