import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Loading,
  Movie,
  VideoItem,
  PopupLoading,
  SearchNoResult,
  SearchResultNumberBox,
  SectionTitle,
} from '@/components/'
import { Pagination } from '@/components/Pagination/Pagination'

import useModal from '@/hooks/useModal'

import styled from '@emotion/styled'
import { CommonLayout } from '@/theme'

import { useSelector } from '@/store'
import { useDispatch } from 'react-redux'
import { videosSagaActions } from '@/store/saga/videosSaga'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { parseSearchParams } from '@/functions/parseSearchParams'

const Container = styled(CommonLayout)`
  min-height: calc(100vh - 317px);

  .search_result_videos_wrapper {
    flex: 1;
    max-height: calc(100% - 166px);
  }

  /* 검색 결과 영역 */
  .search_result_inner {
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .videos_content_wrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;

    ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
    }
  }
`

export function SearchResultMovie() {
  /** router */
  const [searchParams] = useSearchParams()

  /** redux */
  const dispatch = useDispatch()
  const { allVideosDataCount, videos } = useSelector((state) => state.videos)
  const { detailData } = useSelector((state) => state.search)

  /** ref */
  const videoItemRef = useRef<HTMLLIElement | null>(null)
  const [videoItemWidth, setVideoItemWidth] = useState(0)

  /** useState */
  const [currentPage, setCurrentPage] = useState(1)
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  /** hooks */
  const { ModalPortal, openModal } = useModal(targetRef)

  /** 팝업 오픈 */
  const handleShowPopup = useCallback(
    (e: React.MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLElement
      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'mov' }))
    },
    [dispatch, openModal],
  )

  const handleKeyDownShowPopup = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      const currentTarget = e.currentTarget as HTMLElement

      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'mov' }))
    },
    [dispatch, openModal],
  )

  /** 페이지네이션 */
  const handlePagination = useCallback(
    (page: number) => {
      setCurrentPage(page)

      dispatch(
        videosSagaActions.getNextVideosSagaAction({
          page,
          countPerPage: 12,
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    if (videoItemRef.current) setVideoItemWidth(videoItemRef.current?.clientWidth)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /** 검색 결과 데이터 요청 */
  useEffect(() => {
    // const keyword = searchParams.get('keyword')
    // const s_event_date = searchParams.get('s_event_date')
    // const e_event_date = searchParams.get('e_event_date')

    // if (keyword === null) return

    // const params: Record<string, string> = {
    //   keyword,
    //   s_event_date: s_event_date ?? '',
    //   e_event_date: e_event_date ?? '',
    // }

    const keyword = searchParams.get('keyword')

    if (keyword === null) return

    const params = { ...parseSearchParams(searchParams), keyword }

    dispatch(videosSagaActions.getVideosSagaAction(params))
  }, [dispatch, searchParams])

  // 데이터가 없는 경우
  if (!videos) {
    return (
      <Container className='search_result_videos_wrapper'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: 'calc(100vh - 317px)',
          }}
        >
          <Loading />
        </div>
      </Container>
    )
  }

  if (allVideosDataCount === 0) {
    return (
      <Container className='search_result_videos_wrapper'>
        <div
          style={{
            width: '100%',
          }}
        >
          <SearchResultNumberBox count={0} />
          <div className='search_result_inner'>
            <SearchNoResult sectionClassName='all' />
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <div className='search_result_videos_wrapper'>
        <SearchResultNumberBox count={allVideosDataCount} />
        <div className='search_result_inner'>
          <SectionTitle title='동영상' />
          <div className='videos_content_wrapper'>
            <ul>
              {videos.map((item) => (
                <VideoItem
                  ref={videoItemRef}
                  key={item.id}
                  id={item.id}
                  dataItem={item}
                  width={videoItemWidth}
                  tabIndex={0}
                  onClick={handleShowPopup}
                  onKeyDown={handleKeyDownShowPopup}
                />
              ))}
            </ul>
          </div>
          {12 < allVideosDataCount && (
            <Pagination
              currentPage={currentPage}
              setCurrent={handlePagination}
              perPage={12}
              totalRecordCount={allVideosDataCount}
            />
          )}
        </div>
      </div>
      <ModalPortal id={'modal_potal'}>{detailData ? <Movie /> : <PopupLoading />}</ModalPortal>
    </Container>
  )
}
